import gql from 'graphql-tag';

const getEmpleadoQuery = gql `
    query empleado($id: ID!) {
        empleado(id: $id) {
            id,
            estado_id,
            horario_id,
            cliente_id,
            empresa_id,
            sucursal_id,
            departamento_id,
            nombre,
            apellido_paterno,
            apellido_materno,
            fecha_nacimiento,
            sexo,
            direccion,
            telefono,
            correo,
            fecha_ingreso,
            fecha_reingreso,
            fecha_baja,
            dias_vacaciones,
            clave,
            estado_civil_id,
            clave_interna,
            foto,
            tiene_hijos,
            rfc,
            curp,
            tipo_contrato_id,
            salario_diario,
            imss,
            fecha_alta_imss,
            tipo_sangre,
            fecha_planta,
            cuenta_fonacot,
            cuenta_infonavit,
            afore,
            nombre_contacto,
            telefono_contacto,
            observaciones,
            nivel_estudios_id,
            puesto_id,
            municipio_id,
            cp_id,
            colonia_id,
            cuentasBancarias{
                id,
                cuenta_bancaria,
                clabe_bancaria,
                banco{
                    nombre
                }
            },
            cliente{
                nombre
            },
            empresa{
                nombre
            },
            ranking{
                valor,
                clima_laboral,
                proyectos_mejoras
            },
            activo,
            baja,
            getAntiguedad,
            getRanking
        }
    }
`

const queries = { getEmpleadoQuery };

export default queries;